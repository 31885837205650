import styled, {css} from 'styled-components';

export const HeaderChildren = styled.main`
    ${({sticky}) => {
        if (!sticky) {
            return null;
        }

        return css`
            margin-top: 116px;

            @media (max-width: 1151px) {
                margin-top: 60px;
            }
        `;
    }}
`;

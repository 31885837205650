import styled from 'styled-components';
import {spacing, green, red} from '@hy-vee/themes';
import {P} from '@hy-vee/web-core/lib/components/typography';

export const ModalContentContainer = styled.div`
    min-height: 300px;
`;

export const EligibleForDeliveryMessage = styled.div`
    min-height: 200px;
    line-height: 1.2;
`;

export const StyledServiceProviderMessage = styled.p`
    font-weight: 500;
`;

export const StyledEligibleForDeliveryStatus = styled.p`
    font-weight: 500;
    color: ${(props) => props.isEligible ? green.mediumGreen : red.darkRed};
`;

export const StyledEligibleForDelivery = styled.div`
    margin-bottom: ${spacing.large};
`;

export const DeliveryEligibleContainer = styled.div`
    display: flex;
    margin-top: ${spacing.extraLarge};
`;

export const EnterAddressLabel = styled(P)`
    font-weight: 500;
    margin-top: ${spacing.extraLarge};
`;

export const DeliveryStatusIcon = styled.div`
    padding-top: ${spacing.extraSmall};
    margin-right: ${spacing.large};
`;

import styled, {css} from 'styled-components';

export const alertText = css`
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    padding: 15px;
    width: 100%;
`;
export const imageSize = '100px';
export const lightBorder = '1px solid #ddd';
export const transitionDuration = '.35s';
export const sitePadding = '20px';
export const siteMaxWidth = '1200px';
export const pageContent = css`
    flex: 1 0 auto;
    max-width: 1200px;
    padding: 0 ${sitePadding};
`;
export const sectionLoader = css`
    height: 100%;
    min-height: 300px;
    position: relative;
    width: 100%;
`;

export const StyledRelativeContainer = styled.div`
    position: relative;

    ${(props) =>
        props.background &&
        css`
            background: #f4f3f3;
        `}
`;

export const StyledFixedContainer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;

    ${(props) =>
        props.background &&
        css`
            background: #f4f3f3;
        `}
`;

export const visibilityToggleMixin = css`
    ${(props) =>
        props.visible !== true &&
        css`
            display: none;
        `};
`;
export const selectedBackgroundColor = '#EDF1ED';

export const fuelSaverBackgroundColor = '#232325';

import {CLEAR_GEOLOCATION_ERROR, SET_REVERSE_GEOCODE_FAILURE} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const handlers = {
    [CLEAR_GEOLOCATION_ERROR]: updateObject('geolocationError'),
    [SET_REVERSE_GEOCODE_FAILURE]: updateObject('geolocationError'),
};

export default createReducer(null, handlers);

// eslint-disable-next-line no-unused-vars
import React from 'react';

import GoogleTagManagerWrapper from './views/authentication/google-tag-manager-wrapper';
import CustomerWrapper from './views/authentication/customer-wrapper';

const HyVeePlusWeb = (props) => (
    <GoogleTagManagerWrapper>
        <CustomerWrapper setCustomerAndStoreData={props.setCustomerAndStoreData}>
            {props.children}
        </CustomerWrapper>
    </GoogleTagManagerWrapper>
);

export default HyVeePlusWeb;

import {TOGGLE_ACTIVE_MODAL} from '../action-types';

import {createReducer} from './reducer-factory';

const handlers = {
    [TOGGLE_ACTIVE_MODAL]: (state, action) =>
        !action.modalName || state === action.modalName ? null : action.modalName,
};

export default createReducer(null, handlers);

// eslint-disable-next-line no-unused-vars
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {useQuery} from '@apollo/client';

import {User} from '../../context/user';
import {getUnleashFeatures} from '../../graphql/queries/customer-and-feature-queries';
import * as featureToggleNames from '../../enums/features';
import {TWO_LEGGED_AUTH_TYPE, THREE_LEGGED_AUTH_TYPE} from '../../enums/auth-types';

const allFeatures = Object.values(featureToggleNames);

const UserDetails = ({children, customer, store}) => {
    const storeCode = store?.storeId;

    const {data, loading, error} = useQuery(getUnleashFeatures, {
        variables: {
            authType: customer.customerUuid ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            featureNames: allFeatures,
            properties: {
                storeCode: storeCode ? String(storeCode) : undefined,
            },
        },
    });

    const content = useMemo(() => ({
        customer,
        error,
        features: data ? data.unleashFeatureToggles : [],
        loading,
        store,
    }), [customer, data, loading, store, error]);

    return (
        <User.Provider value={content}>
            {children}
        </User.Provider>
    );
};

const mapStateToProps = (state) => ({
    customer: state.customer,
    store: state.store,
});

export default connect(mapStateToProps)(UserDetails);

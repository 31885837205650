import gql from 'graphql-tag';

export const searchDeliveryLocations = gql`
    query SearchDeliveryLocations(
        $addressOne: String
        $city: String
        $state: String
        $zip: String!
        $latitude: Float!
        $longitude: Float!
    ) {
        fulfillmentLocations(
            addressOne: $addressOne
            city: $city
            state: $state
            zip: $zip
            latitude: $latitude
            longitude: $longitude
        ) {
            fulfillmentLocationId
            deliveryProviderNames
            fulfillmentStore {
                city
                state
                zip
                name
            }
        }
    }
`;

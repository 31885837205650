import {ApolloClient, defaultDataIdFromObject, from, InMemoryCache} from '@apollo/client';

import {isServerSide} from '../lib/env';

import {getHeadersMiddleware, getHttpLink} from './graphql-client-utils';

let apolloClient;

const createClient = (initialState = {}, ssrContext = null) =>
    new ApolloClient({
        cache: new InMemoryCache({
            cacheRedirects: {
                Query: {
                    unleashFeatureToggles: (_, args, {getCacheKey}) =>
                        args.featureNames.map((featureName) =>
                            getCacheKey({
                                __typename: 'UnleashFeatureToggle',
                                featureName,
                            }),
                        ),
                },
            },
            dataIdFromObject: (object) => {
                if (object.__typename === 'UnleashFeatureToggle') {
                    return `unleashFeatureToggles:${object.featureName}`;
                }

                if (object.__typename === 'CustomerMembership') {
                    return `customerMembership:${object.customerMembershipId}`;
                }

                return object[`${object.__typename}Id`]
                    ? `${object.__typename}:${object[`${object.__typename}Id`]}`
                    : defaultDataIdFromObject(object);
            },
            typePolicies: {
                Query: {
                    fields: {
                        giftCards: {
                            merge: (_, incoming, {readField}) => {
                                const giftCards = incoming
                                    .filter((giftCard) => readField('balance', giftCard) !== 0)
                                    .sort((giftCard, otherGiftCard) => readField('balance', giftCard) > readField('balance', otherGiftCard) ? 1 : -1);

                                return giftCards;
                            },
                        },
                    },
                },
            },
        }).restore(initialState),
        connectToDevTools: true,
        link: from([getHeadersMiddleware(ssrContext), getHttpLink()]),
        name: 'hy-vee-plus-web',
        ssrMode: Boolean(ssrContext),
    });

export const graphqlClient = (initialState, ssrContext) => {
    if (isServerSide()) {
        return createClient(initialState, ssrContext);
    }

    if (!apolloClient || initialState) {
        apolloClient = createClient(initialState);

        return apolloClient;
    }

    return apolloClient;
};

import {combineReducers} from 'redux';

import geolocationError from './geolocation-error-reducer';
import deliveryAddressCheckerValues from './delivery-address-checker-values-reducer';

export default () =>
    combineReducers({
        deliveryAddressCheckerValues,
        geolocationError,
    });

// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Query} from '@apollo/client/react/components';
import {red, green} from '@hy-vee/themes';
import CheckCircleIcon from '@hy-vee/icons/lib/CheckCircleIcon';
import ClearCircleIcon from '@hy-vee/icons/lib/ClearCircleIcon';

import {getCityStateZip} from '../../utils/view-helpers/address-view-helpers';
import {searchDeliveryLocations} from '../../graphql/queries/fulfillment-location-queries';
import {TWO_LEGGED_AUTH_TYPE} from '../../enums/auth-types';

import {
    DeliveryEligibleContainer,
    DeliveryStatusIcon,
    EligibleForDeliveryMessage,
    StyledEligibleForDelivery,
    StyledEligibleForDeliveryStatus,
    StyledServiceProviderMessage,
} from './styles';

const getIcon = (isEligible) =>
    isEligible ? <CheckCircleIcon
        color={green.mediumGreen}
        height={30}
        width={30} /> :
        // eslint-disable-next-line react/jsx-indent
        <ClearCircleIcon
            color={red.darkRed}
            height={30}
            width={30} />;

const CheckDeliveryEligibilityQuery = ({deliveryAddressCheckerValues, geolocationError, resetError}) => (
    <Query
        query={searchDeliveryLocations}
        variables={{
            addressOne: deliveryAddressCheckerValues.addressOne,
            authType: TWO_LEGGED_AUTH_TYPE,
            city: deliveryAddressCheckerValues.city,
            latitude: deliveryAddressCheckerValues.latitude,
            longitude: deliveryAddressCheckerValues.longitude,
            state: deliveryAddressCheckerValues.state,
            zip: deliveryAddressCheckerValues.zip,
        }}
    >
        {({loading, data}) => {
            if (geolocationError) {
                resetError();
            }

            let statusMessage,
                isEligible;
            const fulfillmentLocations = data?.fulfillmentLocations;

            if (fulfillmentLocations && fulfillmentLocations?.length) {
                statusMessage = 'Eligible for delivery!';
                isEligible = true;
            } else {
                statusMessage = 'Sorry, this address is outside our delivery area.';
                isEligible = false;
            }

            return (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {!loading && (
                        <DeliveryEligibleContainer>
                            <DeliveryStatusIcon>{getIcon(isEligible)}</DeliveryStatusIcon>
                            <EligibleForDeliveryMessage>
                                <StyledEligibleForDelivery>
                                    <StyledEligibleForDeliveryStatus
                                        data-testid="eligibility-message"
                                        isEligible={isEligible}
                                    >
                                        {statusMessage}
                                    </StyledEligibleForDeliveryStatus>
                                    <p>{String(deliveryAddressCheckerValues.formattedAddress)}</p>
                                </StyledEligibleForDelivery>
                                {isEligible && (
                                    <>
                                        <StyledServiceProviderMessage>
                                            {'Service provided by:'}
                                        </StyledServiceProviderMessage>
                                        <p>{fulfillmentLocations[0].fulfillmentStore.name}</p>
                                        <p>{getCityStateZip(fulfillmentLocations[0].fulfillmentStore)}</p>
                                    </>
                                )}
                            </EligibleForDeliveryMessage>
                        </DeliveryEligibleContainer>
                    )}
                </>
            );
        }}
    </Query>
);

export default CheckDeliveryEligibilityQuery;

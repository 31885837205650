import {
    SET_REVERSE_GEOCODE_SUCCESS,
    SET_REVERSE_GEOCODE_REQUEST,
    SET_REVERSE_GEOCODE_FAILURE,
} from '../action-types';

export const reverseGeocodeLocation = (latitude, longitude) => (dispatch) =>
    dispatch({
        fetch: {
            onFailure: (dispatchFromMiddleware) => {
                dispatchFromMiddleware({
                    deliveryAddressCheckerValues: {},
                    geolocationError: 'We could not detect your location. Please type your address.',
                    type: SET_REVERSE_GEOCODE_FAILURE,
                });
            },
            onSuccess: (dispatchFromMiddleware, state, data) => {
                dispatchFromMiddleware({
                    type: SET_REVERSE_GEOCODE_SUCCESS,
                    ...data,
                });
            },
            url: `/aisles-online/api/google-api/reverse-geocode?latitude=${latitude}&longitude=${longitude}`,
        },
        type: SET_REVERSE_GEOCODE_REQUEST,
    });

import {FOUND} from 'http-status';
import Router from 'next/router';

export const redirectToPath = (path, res) => {
    if (res) {
        res.writeHead(FOUND, {
            Location: path,
        });
        res.end();
    } else {
        Router.replace(path);
    }
};

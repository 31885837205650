import {useContext} from 'react';

import withUserDetails from '../utils/with-user-details';
import {hasFeature} from '../utils/features';
import {User} from '../context/user';

const FeatureToggle = ({featureName, component, fallback = null}) => {
    const {features, loading} = useContext(User);

    // eslint-disable-next-line no-extra-parens
    return !loading ? (hasFeature(features, featureName) ? component : fallback) : null;
};

export default withUserDetails(FeatureToggle);

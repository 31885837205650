import {useRef} from 'react';
import {GlobalNavigationHeader, GlobalNavigationFooter, GlobalNavigationMainContent, GlobalNavigationSkipToContentLink} from '@hy-vee/global-navigation';
import {NextSeo} from 'next-seo';

import {HeaderChildren} from '../styles/header-styles';
import {StyledFixedContainer, StyledRelativeContainer} from '../styles/style-constants';
import {redirectToPath} from '../utils/redirect';
import {HYFISH_WEB_HEADER, HYFISH_WEB_FOOTER} from '../enums/features';

import FeatureToggle from './feature-toggle';

const HeaderPage = ({
    background = false,
    customer,
    children,
    seo,
    sticky = false,
}) => {
    const userData = Object.entries(customer || {}).length ? customer : null;
    const HeaderStickyContainer = sticky ? StyledFixedContainer : StyledRelativeContainer;
    const startOfContentRef = useRef(null);

    return (
        <>
            <GlobalNavigationSkipToContentLink startOfContentRef={startOfContentRef} />
            <NextSeo {...seo} />
            <StyledRelativeContainer background={background}>
                <HeaderStickyContainer>
                    <FeatureToggle
                        component={
                            <GlobalNavigationHeader
                                featureFlagHeaderRed
                                onRouteChange={redirectToPath}
                            />
                        }
                        fallback={
                            <GlobalNavigationHeader
                                onRouteChange={redirectToPath}
                            />
                        }
                        featureName={HYFISH_WEB_HEADER}
                    />
                </HeaderStickyContainer>
                <GlobalNavigationMainContent startOfContentRef={startOfContentRef}>
                    <HeaderChildren
                        loggedIn={Boolean(userData)}
                        sticky={sticky}>
                        {children}
                    </HeaderChildren>
                </GlobalNavigationMainContent>
                <FeatureToggle
                    component={
                        <GlobalNavigationFooter featureFlagFooterRed />
                    }
                    fallback={
                        <GlobalNavigationFooter />
                    }
                    featureName={HYFISH_WEB_FOOTER}
                />
            </StyledRelativeContainer>
        </>
    );
};

export default HeaderPage;

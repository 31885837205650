import gql from 'graphql-tag';

export const getActiveMembershipBenefitsWithLinks = gql`
    query GetActiveMembershipBenefitsWithLinks(
        $membershipType: MembershipType!
    ) {
        activeMembershipBenefits(membershipType: $membershipType)
        {
            displayOrder
            endDate
            header
            id
            imageUrl
            legalText
            mainContent
            memberContent
            membershipType
            startDate
            subContent
            links {
                text
                destination
                linkType
            }
        }
    }
`;

export const getMyCustomerMemberships = gql`
    query GetMyCustomerMemberships($afterDate: String!) {
        myCustomer {
            customerUuid
            customerMemberships: customerMembershipsV2 {
                customerMembershipId
                expirationDate
                creationDate
                cancellationDate
                refundExpirationDate
                isActive
                isCanceled
                customerMembershipTransactions(afterDate: $afterDate) {
                    customerMembershipTransactionId
                    confirmationNumber
                    purchasePrice
                    purchaseDate
                    transactionType
                    receiptUri
                }
                membership {
                    membershipId
                    membershipType
                    subDescription
                    description
                    durationUnit
                    durationInterval
                    price
                    refundEligibilityInDays
                }
            }
            fuelSaverCard {
                isFullLoyaltyMember
                lifestyleSegments
                fuelSaverCardUuid
            }
        }
    }
`;

export const getMyActiveCustomerMemberships = gql`
    query GetMyCustomerMembershipsNoTransactions {
        myCustomer {
            customerUuid
            customerMemberships: customerMembershipsV2(isActive: true, membershipType: HYVEE_PLUS) {
                customerMembershipId
                membership {
                    membershipId
                    membershipType
                }
            }
            fuelSaverCard {
                isFullLoyaltyMember
                lifestyleSegments
            }
        }
    }
`;

export const getActiveMemberships = gql`
    query GetActiveMemberships($membershipType: MembershipType!)
    {
        activeMemberships(membershipType: $membershipType)
        {
            membershipId
            price
            isActive
            durationUnit
            durationInterval
            membershipType
            description
            subDescription
            refundEligibilityInDays
        }
    }
`;

export const getEndUserLicenseAgreements = gql`
    query ($where: endUserLicenseAgreementInput!)
    {
        endUserLicenseAgreements(where: $where)
        {
            endUserLicenseAgreementId
            agreementUrl
        }
    }
`;

export const getTaxByCustomerCardBillingAddress = gql`
    query ($finalMembershipPrice: Float!, $billingAddress: BillingAddress!){
        membershipTax(finalMembershipPrice: $finalMembershipPrice, billingAddress: $billingAddress) {
            totalTax
        }
    }
`;

export const getCustomerCards = gql`
    query GetCustomerCards($customerUuid: ID!){
        customerCardsV2(customerUuid: $customerUuid) {
            billingAddress
            city
            customerCardId
            customerUuid
            defaultPaymentMethod
            expirationMonth
            expirationYear
            issuer
            paymentNickname
            state
            token
            zip
        }
    }
`;

export const getGiftCards = gql`
    query GetGiftCards($customerId: Int!){
        giftCards(customerId: $customerId) {
            giftCardId
            customerId
            cardNumber
            paymentNickname
            balance
        }
    }
`;

export const createGiftCard = gql`
    mutation CreateGiftCard($giftCardInput: createGiftCardInput!) {
        createGiftCard(giftCardInput: $giftCardInput) {
            giftCardId
            customerId
            cardNumber
            paymentNickname
            balance
        }
    }
`;

export const purchaseCustomerMembership = gql`
    mutation PurchaseCustomerMembership($request: PurchaseCustomerMembershipRequest!) {
        purchaseCustomerMembership(request: $request) {
            customerMembershipId
            customerMembershipTransactions {
                customerMembershipTransactionId
                confirmationNumber
            }
        }
    }
`;

export const purchaseCustomerMembershipV2 = gql`
    mutation PurchaseCustomerMembershipV2($request: PurchaseCustomerMembershipV2Request!) {
        purchaseCustomerMembershipV2(request: $request) {
            customerMembershipId
            customerMembershipTransactions {
                customerMembershipTransactionId
                confirmationNumber
            }
        }
    }
`;

export const deleteGiftCard = gql`
    mutation DeleteGiftCard($giftCardId: ID!) {
        deleteGiftCard(giftCardId: $giftCardId)
    }
`;

export const mutationReactivateCanceledCustomerMembership = gql`
    mutation Reactivate {
        reactivateCustomerMembership
        {
            customerMembershipId
            expirationDate
            cancellationDate
            isActive
            isCanceled
        }
    }
`;

export const mutationCancelCustomerMembership = gql`
    mutation Cancel($customerId: Int!) {
        cancelCustomerMembership(customerId: $customerId)
        {
            customerMembershipId
            expirationDate
            cancellationDate
            isActive
            isCanceled
        }
    }
`;

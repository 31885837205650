import {
    SET_DELIVERY_ADDRESS_CHECKER_VALUES,
    SET_REVERSE_GEOCODE_FAILURE,
    SET_REVERSE_GEOCODE_SUCCESS,
} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const handlers = {
    [SET_DELIVERY_ADDRESS_CHECKER_VALUES]: updateObject('deliveryAddressCheckerValues'),
    [SET_REVERSE_GEOCODE_FAILURE]: updateObject('deliveryAddressCheckerValues'),
    [SET_REVERSE_GEOCODE_SUCCESS]: updateObject('deliveryAddressCheckerValues'),
};

export default createReducer({}, handlers);
